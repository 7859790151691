import { httpClient } from './axiosClients'

export function getBufferPeriodChangeReasons () {
  return httpClient.get('/api/buffer-period-change-reasons')
}

export function getPortalOffices (params) {
  return httpClient.get('/api/portal-offices', {
    params
  })
}
