var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{ref:"modal",attrs:{"title":"Opties voor publicatie"}},[_c('FormulateForm',{attrs:{"name":"portalOptions"},on:{"submit":_vm.updatePortalOptions},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('div',{staticClass:"tw-grid tw-grid-cols-2 tw-gap-x-4 tw-w-full"},[_c('FormulateInput',{attrs:{"type":"date","name":"start","label":"Startdatum","placeholder":"Startdatum","validation":"bail|required|dateIsOriginalStartDateOrFuture","validation-rules":{ dateIsOriginalStartDateOrFuture: _vm.dateIsOriginalStartDateOrFuture  },"validation-messages":{
          dateIsOriginalStartDateOrFuture : 'De startdatum mag niet vroeger ingesteld worden.',
        }}}),_c('FormulateInput',{attrs:{"type":"date","name":"stop","label":"Eindddatum","placeholder":"Einddatum"}})],1),(_vm.showLinkUrlField)?_c('div',{staticClass:"tw-flex tw-gap-x-4 tw-w-full"},[_c('FormulateInput',{attrs:{"type":"text","name":"link_url","label":"Url","placeholder":"Url","validation":"bail","outer-class":"tw-w-full"}})],1):_vm._e(),_c('div',{staticClass:"tw-flex tw-gap-x-4"},_vm._l((_vm.filteredOptions),function(option){return _c('FormulateInput',{key:option.name,attrs:{"type":"checkbox","name":option.name,"label":_vm.getOptionLabel(option),"disabled":_vm.shouldDisableOption(option)},model:{value:(option.value),callback:function ($$v) {_vm.$set(option, "value", $$v)},expression:"option.value"}})}),1),_c('FormulateErrors'),_c('div',{staticClass:"tw-flex tw-flex-row tw-space-x-4 tw-justify-between tw-items-baseline"},[_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading}},[_c('i',{class:[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
          ]}),_vm._v(" Opslaan ")])],1)]}}]),model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }