<template>
  <PanelBox title="Publiceren op websites en portaalsites">
    <template slot="toolbar">
      <button
        title="Historiek"
        class="toolbar-button"
        @click="showHistory"
      >
        Historiek
      </button>
    </template>
    <div v-if="portals && publications">
      <div v-if="(property && property.published) || (project && project.published)">
        <div v-for="portal in portals" :key="portal.id" class="row margin-bottom-5">
          <div class="col-sm-3">
            {{ portal.name }}
          </div>
          <div class="col-sm-9">
            <!-- Don't show globe when price isn't set to visible and property is NOT published to Immoweb -->
            <!-- Allow "depublishing" of entities that have an active publication -->
            <div v-if="showImmowebPriceNotPublishedNotification(portal.id)">
              Prijs niet gepubliceerd, publicatie Immoweb niet mogelijk
            </div>
            <div v-else-if="hideGlobe(portal.id)" />
            <button
              v-else
              type="button"
              :disabled="isGlobeButtonDisabled(portal)"
              :class="activePublications[portal.id] ? 'btn-primary' : 'btn-default'"
              class="btn btn-xs tw-mr-1"
              @click="togglePortal(portal)"
            >
              <i class="glyphicon glyphicon-globe" />
            </button>
            <a
              v-if="(property||project) && activePublications[portal.id] && activePublications[portal.id].url"
              target="_blank"
              class="btn btn-default btn-xs"
              :href="activePublications[portal.id].url"
            >
              <i class="fa fa-chain" />
            </a>
            <button
              v-if="activePublications[portal.id]"
              type="button"
              class="btn btn-xs btn-default tw-mx-1"
              @click="showPortalPublicationDetailsEditModal(portal)"
            >
              Opties
            </button>
           <PortalPublicationDateInformation
              v-if="activePublications[portal.id]"
              :start="activePublications[portal.id].start"
              :stop="activePublications[portal.id].stop"
            />
            <span v-if="showImmowebFormula(portal.id)" class="tw-border tw-px-2 tw-ml-1">
              {{ immowebFormula }}
            </span>
          </div>
        </div>
      </div>
      <div v-else>
        <em>Deze entiteit is niet gepubliceerd</em>
      </div>
      <PortalPublicationDetailsEditModal
        ref="portalPublicationDetailsEditModal"
        :portal-options="portalOptions"
        :portal-option-values="portalOptionValues"
        :property="property"
        :project="project"
        @publicationUpdated="loadPublicationData"
      />
    </div>
    <BaseModal ref="historyModal" title="Historiek publicaties naar websites en portaalsites" max-wdith="tw-max-w-xl">
      <FormulateForm
        #default="{ isLoading }"
        :key="key"
        name="bufferPeriodForm"
        invalid-message="Gelieve de verplichte velden correct in te vullen."
        @submit="toggleBufferPeriod"
      >
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Kanaal</th>
              <th>Start</th>
              <th>Gestart door</th>
              <th>Stop</th>
              <th>Gestopt door</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <PortalPublicationDetails
              v-for="publication in publications"
              :key="publication.id"
              :publication="publication"
              :portals="portals"
              @update="loadPublicationData()"
            />
          </tbody>
        </table>
        <fieldset v-if="publications.length && canChangeBufferPeriod" class="tw-my-4 tw-px-4 tw-border-gray-cc tw-rounded">
          <FormulateInput
            type="checkbox"
            v-model="isBufferPeriodSet"
            label="Bufferperiode ingesteld"
          />
          <FormulateInput
            v-if="isBufferPeriodSetInitialValue === true"
            type="select"
            v-model="bufferPeriodChangeReasonId"
            label="Reden voor het uitzetten van de bufferperiode"
            :options="bufferPeriodChangeReasons"
            placeholder="Selecteer een reden"
            validation="required"
          />
          <FormulateInput
            type="text"
            v-model="comment"
            label="Opmerking"
            :placeholder="conditionalBufferPeriodPlaceholder"
            validation='commentIsRequiredIfTypeIsOther'
            :validation-rules="{commentIsRequiredIfTypeIsOther}"
            :validation-messages="{
              commentIsRequiredIfTypeIsOther: 'Opmerking is verplicht als de reden Andere is'
            }"
          />
          <FormulateInput
            type="submit"
            :disabled="isLoading || !isBufferPeriodSetInitialValueChanged"
          >
            <i
              :class="[
                'fas tw-mr-1',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
              ]"
            />
            Opslaan
          </FormulateInput>
        </fieldset>
        <div v-else-if="publications.length" class="tw-font-bold tw-ml-2">
          Bufferperiode <u v-if="!isBufferPeriodSet">niet</u> ingesteld.
        </div>
      </FormulateForm>
    </BaseModal>
  </PanelBox>
</template>

<script>
import Vue from 'vue'
import { isAfter } from 'date-fns'
import PanelBox from '@/components/iam/PanelBox'
import PortalPublicationDetails from '@/components/properties/PortalPublicationDetails'
import PortalPublicationDetailsEditModal from '@/components/properties/PortalPublicationDetailsEditModal'
import { successModal, errorModal, questionModal, warningModal } from '@/modalMessages'
import { getPropertyFinancial, getPropertyPortalPublications, createPropertyPortalPublication, updatePropertyPortalPublication, getPortals, togglePropertyPublicationBufferPeriod } from '@/services/properties'
import { getProjectPortalPublications, createProjectPortalPublication, updateProjectPortalPublication, toggleProjectPublicationBufferPeriod } from '@/services/projects'
import { getBufferPeriodChangeReasons } from '@/services/portals'
import { mapGetters } from 'vuex'
/**
 * This component displays the start and stop date of a PortalPublication in textual format
 */
const PortalPublicationDateInformation = Vue.component('portal-publication-date-information', {
  template: '<span><template v-if=start>Start: {{ start|date-day }}</template><template v-if="stop"> - Stop: {{ stop|date-day }}</template></span>',
  props: ['start', 'stop']
})

export default {
  name: 'PortalPublications',
  components: { PortalPublicationDetails, PortalPublicationDetailsEditModal, PanelBox, PortalPublicationDateInformation },
  props: {
    property: {
      type: Object,
      default: null
    },
    project: {
      type: Object,
      default: null
    },
    marketing: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      portals: [],
      publications: [],
      selectedPortal: null,
      portalOptionValues: {},
      portalOptions: [],
      datePickerConfig: {
        format: 'YYYY-MM-DD',
        locale: 'nl-be',
        useCurrent: false
      },
      isInvestmentProperty: false,
      isBufferPeriodSet: false,
      isBufferPeriodSetInitialValue: false,
      comment: '',
      bufferPeriodChangeReasonId: null,
      isLoading: false,
      key: 1,
      bufferPeriodChangeReasons: []
    }
  },
  created () {
    this.init()
  },
  constants: {
    IMMOWEB_PORTAL_ID: 5,
    IMMOWEB_L_NAME: '1',
    IMMOWEB_XL_NAME: '2'
  },
  computed: {
    ...mapGetters('account', ['collaborator']),

    activePublications () {
      let entityType = ''
      if (this.property) {
        entityType = 'property'
      } else if (this.project) {
        entityType = 'project'
      }

      const pubs = {}
      if (this.publications) {
        Object.values(this.publications).forEach(pub => {
          if (pub.stop === null || isAfter(new Date(pub.stop), new Date())) {
            if (entityType === 'property') {
              // A publication is 'active' in the UI if the publication is not made through a project connection
              if (!pub.project) {
                pubs[pub.portal] = pub
              }
            } else {
              // A publication is 'active' in the UI if the publication is not made through a project connection
              if (!pub.property) {
                pubs[pub.portal] = pub
              }
            }
          }
        })
      }
      return pubs
    },

    isBufferPeriodSetInitialValueChanged () {
      return this.isBufferPeriodSet !== this.isBufferPeriodSetInitialValue
    },

    canChangeBufferPeriod () {
      return this.collaborator.hasPerm('CHANGE_BUFFER_PERIOD')
    },

    conditionalBufferPeriodPlaceholder () {
      if (this.bufferPeriodChangeReasonId && parseInt(this.bufferPeriodChangeReasonId) === 4) return 'Verplichte opmerking'
      return 'Optionele opmerking'
    },

    immowebFormula () {
      const portalPublication = this.activePublications[this.IMMOWEB_PORTAL_ID]
      if (portalPublication?.options['1']) return 'L'
      if (portalPublication?.options['2']) return 'XL'
      return ''
    }
  },
  methods: {
    async init () {
      try {
        const [portalsResponse, publicationsResponse, propertyFinancialResponse] = await Promise.all([getPortals(), this.loadPublicationData(), ...this.property ? [getPropertyFinancial(this.property.id)] : []])
        this.portals = portalsResponse?.data?.results
        this.isInvestmentProperty = propertyFinancialResponse?.data?.is_investment_property
        this.isBufferPeriodSet = this.marketing.is_buffer_period_set
        this.isBufferPeriodSetInitialValue = this.marketing.is_buffer_period_set

        return [portalsResponse, publicationsResponse, propertyFinancialResponse]
      } catch (error) {
        console.error(error)
        errorModal('Portaalpublicaties kunnen niet worden ingeladen.')
      }
    },
    showPortalPublicationDetailsEditModal (portal) {
      this.selectedPortal = portal
      const publication = this.isPublishedToPortal(this.selectedPortal)
      this.$refs.portalPublicationDetailsEditModal.setFormValues(publication, portal)
      this.$refs.portalPublicationDetailsEditModal.show()
    },
    hidePortalPublicationDetailsEditModal () {
      this.$refs.optionModal.hide()
    },
    async loadPublicationData () {
      let response = null
      if (this.property) response = await getPropertyPortalPublications(this.property.id)
      if (this.project) response = await getProjectPortalPublications(this.project.id)
      this.publications = response.data?.results
      return response
    },
    async showHistory () {
      if (!this.bufferPeriodChangeReasons.length) {
        const response = await getBufferPeriodChangeReasons()
        this.bufferPeriodChangeReasons = response.data?.results?.map(obj => { return { value: obj.id, label: obj.name } })
      }
      this.$refs.historyModal.show()
    },
    isPublishedToPortal: function (portal) {
      return this.activePublications[portal.id]
    },
    async togglePortal (portal) {
      const publication = this.isPublishedToPortal(portal)
      if (publication) {
        const result = await questionModal(`Wil je dit pand offline halen van portaalsite ${portal.name}?`)
        if (result.value) {
          try {
            const payload = { action: 'stop' }
            if (this.property) await updatePropertyPortalPublication(this.property.id, publication.id, payload)
            if (this.project) await updateProjectPortalPublication(this.project.id, publication.id, payload)
            const publicationsResponse = await this.loadPublicationData()
            return publicationsResponse
          } catch (e) {
            console.error(e)
            errorModal('Er ging iets mis bij het offline halen')
          }
        }
      } else {
        // Check if the user can publish to this portal?
        if (portal.restricted && !this.collaborator.is_staff) {
          return warningModal('Vanderbuild panden kunnen enkel gepubliceerd worden door medewerkers van Marketing. Vraag de publicatie ' +
              'aan bij de collega\'s van Marketing.')
        }
        try {
          const payload = { portal: portal.id }
          if (this.property) {
            await createPropertyPortalPublication(this.property.id, payload)
          }
          if (this.project) await createProjectPortalPublication(this.project.id, payload)
          const publicationsResponse = await this.loadPublicationData()
          return publicationsResponse
        } catch (error) {
          console.error(error)
          if (error.response?.data?.message?.[0]) {
            errorModal(`Er ging iets mis bij het publiceren: ${error.response?.data?.message?.[0]}`)
          } else {
            errorModal('Er ging iets mis, gelieve opnieuw te proberen')
          }
        }
      }
    },
    hideGlobe (portalId) {
      const patrimonyCompanyPropertype = 96

      const vanderbuildPortal = 2
      const turnerPortal = 3
      const dewaelePortal = 6
      const immoscoopPortal = 9

      // Vanderbuild and Turner portals are gone since the monobrand (September 2022)
      // Do not allow publishing, but still show the portal name
      if (portalId === vanderbuildPortal || portalId === turnerPortal) return true

      // Do not show Immoscoop portal for projects
      if (portalId === immoscoopPortal && this.project) return true

      // For patrimony company properties only the Dewaele portal globe should be shown
      if (this.property?.type?.id !== patrimonyCompanyPropertype) return false
      else if (portalId !== dewaelePortal) return true
    },
    showImmowebPriceNotPublishedNotification (portalId) {
      const patrimonyCompanyPropertype = 96

      if (this.property?.type?.id === patrimonyCompanyPropertype || this.project) return false
      return portalId === this.IMMOWEB_PORTAL_ID && !this.property?.publish_price && !this.activePublications[portalId]
    },
    showImmowebFormula (portalId) {
      if (portalId !== this.IMMOWEB_PORTAL_ID) return false
      const portalPublication = this.activePublications[portalId]
      if (!portalPublication) return false
      if (portalPublication?.options['1']) return true
      if (portalPublication?.options['2']) return true
    },
    async toggleBufferPeriod () {
      try {
        this.isLoading = true
        const payload = {
          is_buffer_period_set: this.isBufferPeriodSet,
          comment: this.comment,
          buffer_period_change_reason_id: this.bufferPeriodChangeReasonId
        }
        const response = this.property
          ? await togglePropertyPublicationBufferPeriod(this.property.id, payload)
          : await toggleProjectPublicationBufferPeriod(this.project.id, payload)
        await this.loadPublicationData()
        this.isBufferPeriodSetInitialValue = this.isBufferPeriodSet
        this.comment = ''
        this.buffer_period_change_reason_id = null
        this.resetForm()
        successModal('De bufferperiode is succesvol gewijzigd.')
        return response
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets mis bij het instellen van de bufferperiode.')
      } finally {
        this.isLoading = false
      }
    },
    resetForm () {
      this.key = Math.random()
    },
    commentIsRequiredIfTypeIsOther () {
      const reason = this.bufferPeriodChangeReasonId
      const comment = this.comment
      // If the reason is not of type 4 (Other) comment is not required
      if (parseInt(reason) !== 4) return true
      // Check if comment is not blank if the reason if of type 4 (Other)
      return !!(comment && comment.trim())
    },
    isGlobeButtonDisabled (portal) {
      // Globe button should be disabled if portal is a social media portal (type 3) and there's no active Dewaele (6) publication
      return portal.type === 3 && !this.activePublications[6]
    }
  }
}
</script>
